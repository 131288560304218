import * as moment from 'moment-timezone'
import { ICountryState } from '../../Core/Domain/ViewModels/PrimaryDetails'
import { ISignerControlDataModal } from 'src/Core/Domain/ViewModels/SignerControlDataModal'
import { ISignAsyncRequestModel } from 'src/Core/Domain/ViewModels/RequestModels'

export function getUserTimeZone() {
    return encodeURIComponent(moment.tz.guess(true))
}

export function getMaskedPhoneNumber(number: string): string {
    let n1, n2, n3, maskedNumber
    if (number && number.length == 10) {
        n1 = number.substring(0, 3)
        n2 = number.substring(3, 6)
        n3 = number.substring(6, 10)
        maskedNumber = '(' + n1 + ')' + ' ' + +n2 + '-' + n3
    } else {
        maskedNumber = number
    }
    return maskedNumber
}

export function prepareCountryStateDropdown(states: ICountryState[]) {
    var columnValues: any = []
    states.map((state: any) =>
        columnValues.push({
            value: state.id,
            label: state.name,
        })
    )

    return columnValues
}

export function countDecimals(value: number) {
    if (value % 1 != 0) return value.toString().split('.')[1].length
    return 0
}

export const hideFinishButton = () => {
    let element = document.querySelector('[data-shepherd-step-id="btnContinue"]')
    element?.setAttribute('hidden', 'true')
}

export const disableBrowserBackButton = () => {
    history.pushState(null, null, location.href)
    window.onpopstate = () => {
        history.go(1)
    }
}

export const prepareSignAsyncRequestModel = (signatureData: ISignerControlDataModal) => {

    const signAsyncRequestModel : ISignAsyncRequestModel = {
        clientGuid: signatureData.clientGuid,
        signature: signatureData.signature,
        documentData: signatureData.documentData,
        signatureInitial: signatureData.signatureInitial,
        userTimeZone: moment.tz.guess(true)
    }
    return signAsyncRequestModel;
}