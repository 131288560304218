import * as React from 'react';
import { FC, useEffect, useState } from 'react';
import { RouteComponentProps } from 'react-router';
import * as LoaderStore from '../../store/Common/LoaderStore';
import { PasswordLockIcon } from '../Svg/CustomSvgIcons';
import { IClientSignatureStatus } from 'src/Core/Domain/ViewModels/ClientSignatureStatus';
import * as SignerStore from '../../store/Signing/SignerStore';
import * as PublicStore from '../../store/Common/PublicStore';
import LoadingOverlay from 'react-loading-overlay';
import { MetroSpinner } from "react-spinners-kit";
import { logger } from '../../routes';
import { disableBrowserBackButton } from '../Helper/HelperFunctions';
import moment from 'moment';

type LockedPageProps = {
    signingStatus: IClientSignatureStatus,
    publicData: PublicStore.IPublicData,
    loaderData: LoaderStore.ILoadingData
} & typeof SignerStore.actionCreators
  & typeof PublicStore.actionCreators
  & RouteComponentProps<{}>;

export const LockedPage: React.FC<LockedPageProps> = (props) => {
    const param: any = props.match.params;
    const [isLocked, setIsLocked] = useState(true);

    useEffect(() => {
        logger.trackPageView('Document locked Page');
        disableBrowserBackButton();
    }, []);

    const handleGoHomeClick = () => {
        props.history.push('/login/' + param.Id);
    };
    const utcDateTime = moment.utc();
    const lockedOnTime = props.publicData.primaryDetails?.authenticationInfo?.lockedOn 
        ? moment.utc(props.publicData.primaryDetails.authenticationInfo.lockedOn)
        : null;
    const canUnlock = lockedOnTime && utcDateTime.isAfter(lockedOnTime.add(10,'minutes'))? true:false;
    useEffect(() => {
        if (canUnlock) {
            setIsLocked(false);
        }
    }, [canUnlock]);

    
    return (
        <LoadingOverlay
            active={props.loaderData.loading}
            spinner={<MetroSpinner size={60} color="#3bbaea" loading={props.loaderData.loading} />}
        >
            <div className="access-code-login-container">
                <div className="access-code-box">
                    <div><PasswordLockIcon /></div>
                    <div className="font16" style={{ marginTop: '5%' }}>
                        The access link has been locked.<br />
                        Please try again in ten minutes or contact your firm for assistance.
                    </div>
                    <button
                        onClick={handleGoHomeClick}
                        disabled={isLocked}
                        style={{ marginTop: '5%', width: '200px' }}
                    >
                        Go Home
                    </button>
                </div>
            </div>
        </LoadingOverlay>
    );
};
